// eslint-disable-next-line no-unused-vars
//后期需要做全局前置守卫（用户需登录才可使用）
import {createRouter,createWebHistory} from "vue-router";

const routes = [
    {
        path: '/',
        name:'CelestialHome',
        alias: ['/index','/home'],
        component: () =>  import("@/pages/Home/HomePage.vue")
    },
    {
        path: '/AboutUs',
        name:'AboutUs',
        component: () =>  import("@/pages/AboutUs/AboutUsPage.vue")
    },
    {
        path: '/DailyHoroscope',
        name:'DailyHoroscope',
        component: () =>  import("@/pages/DailyHoroscope/DailyHoroscopePage.vue")
    },
    {
        path: '/OnlineDivinastion',
        name:'OnlineDivinastion',
        component: () =>  import("@/pages/OnlineDivination/OnlineDivinationPage.vue")
    },
]

export default createRouter({
    history:createWebHistory(),
    routes
})