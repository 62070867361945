<template>
    <footer>
        <p>{{ t('CelestialFooter.copyright') }}</p>
        <p>{{ t('CelestialFooter.mailbox') }}:xxxx@qq.com</p>
    </footer>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
    name:'CelestialFooter',
    setup() {
        const { t } = useI18n();
        return { t };
    }
}
</script>

<style scoped>
    footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5%;
        background-color: #443256;
        color: #fff;
        font-size: 16px;
        font-family: 'SiYuanRegular';
    }
    @media(max-width: 1200px) {
        footer {
            display: none
        }
    }
</style>