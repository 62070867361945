import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      // 定义状态
      showLoginOverlay: false,
      // ... 其他状态
    };
  },
  mutations: {
    setShowLoginOverlay(state, payload) {
      state.showLoginOverlay = payload;
    },
    // ... 其他突变
  },
  actions: {
    // 定义异步操作
    // ... 
  },
  getters: {
    // 定义计算属性
    // ... 
  }
});
