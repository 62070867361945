import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './includes/i18n.js'
import store from './store';
import 'view-ui-plus/dist/styles/viewuiplus.css'



window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
const app = createApp(App);
app.use(router).use(i18n).use(store).mount('#app');