import { createI18n } from 'vue-i18n'
import cn from "@/locales/cn.json";
import en from "@/locales/en.json";
import jp from "@/locales/jp.json";

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
        cn,
        jp,
    },
})
export function changeLocale(locale) {
    i18n.global.locale.value = locale;
}

export default i18n;
