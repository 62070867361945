<template>
  <div>
        <!-- 头部 -->
        <header>
            <!-- 头部左部分 -->
            <nav>
                <img :src="logoImage" alt="logo" id="logo">
                <!-- 导航栏 -->
                <ul>
                    <router-link
                        v-for="link in links"
                        :key="link.name"
                        :to="{ name: link.name }"
                        :class="{ 'active_link': $route.name === link.name }"
                    >
                        {{ link.text }}
                    </router-link>
                </ul>
            </nav>
            <!-- 头部右部分 -->
            <div>
                <div>
                    <!-- 当前日期 -->
                    <div id="date">
                        <p>{{ formattedDate() }}</p>
                        <p>{{ week }}</p>
                    </div>
                    <!-- 月相 -->
                    <div id="MoonPhase">
                        <img :src="GetMoonPhaseSrc" alt="moonphase">
                        <div id="constellation">
                            <img :src="ConstellationImage" alt="constellationImage" v-if="ConstellationContentShow">
                        </div>
                    </div>
                </div>
                <!-- 头像 -->
                <div 
                    id="ProfilePhoto" 
                    class="SideBarPortrait ProfileBG" 
                    @click="ClickSidebar" 
                    type="primary"
                >
                    <img 
                        :src="UserImage" 
                        alt="profile photo"
                    >
                </div>
                <p id="login" @click="LoginToggleOpen">Login</p>
            </div>
        </header>
        <!-- 侧边栏 -->
        <div class="sidebar" v-if="SidebarShow" v-click-sidebar-outside="closeSidebar">
            <div id="SidebarClose">
                <p @click="ForgetSidebarClose">×</p>
            </div>
            <div class="SidebarHeader">
                <!-- 头像 -->
                <div class="SidebarAvatar">
                    <img :src="UserImage" alt="head portrait">
                </div>
                <div class="userinfo">
                    <span v-if="!isEditing">
                        {{ user.name }}
                        <Icon 
                            class="ivu-icon ivu-icon-ios-create-outline"
                            id="ios-create" 
                            @click.stop="toggleEditing"
                        />
                    </span>
                    <input
                        v-else
                        type="text"
                        v-model="user.name"
                        @blur="toggleEditing"
                        @keyup.enter="toggleEditing"
                        ref="nameInput"
                    />
                    <div>
                        <img :src="IsNobility" alt="IsNobility">
                        <span>{{ ServiceLife }}</span>
                    </div>
                </div>
            </div>
            <ul class="SidebarContent">
                <li>
                    <p>{{ t('CelestialHeader.Sidebar.Birthday') }}</p>
                    <div class="userbirthday">
                        <span 
                            v-if="!isEditingBirthday"
                            @click.stop="toggleEditingBirthday"
                        >
                            {{ user.birthday }}
                        </span>
                        <input
                            v-else
                            type="date"
                            v-model="user.birthday"
                            @blur="toggleEditingBirthday"
                            @keyup.enter="toggleEditingBirthday"
                            ref="BirthdayInput"
                        />
                    </div>
                    
                </li>
                <li @click="ClickSubscription">
                    <p>{{ t('CelestialHeader.Sidebar.Subscription.Subscription') }}</p>
                    <p class="gt">&gt;</p>
                </li>
                <div class="SidebarNav">
                    <router-link
                        v-for="link in links"
                        :key="link.name"
                        :to="{ name: link.name }"
                        @click="ForgetSidebarClose"
                        >
                        <li>
                            {{ link.text }}
                            <p class="gt">&gt;</p>
                        </li>
                    </router-link>
                </div>
                <li class="LanguageLink" @click="ToggleLanguage">
                    <p>{{ t('CelestialHeader.Sidebar.Language.Language') }}</p>
                    <p class="gt" ref="LanguageGT">&gt;</p>
                </li>
                <ul class="LanguageContent" ref="LanguageContent">
                    <li @click="switchLocale('en')" v-if="LanguageContentChildrenShow">{{ t('CelestialHeader.Sidebar.Language.English') }}</li>
                    <li @click="switchLocale('cn')" v-if="LanguageContentChildrenShow">{{ t('CelestialHeader.Sidebar.Language.Chinese') }}</li>
                    <li @click="switchLocale('jp')" v-if="LanguageContentChildrenShow">{{ t('CelestialHeader.Sidebar.Language.Japanese') }}</li>
                </ul>
                <li @click="ToggleDate">
                    <p>{{ t('CelestialHeader.Sidebar.Date') }}</p>
                    <p class="gt" ref="DateGT">&gt;</p>
                </li>
                <ul class="DateContent" ref="DateContent">
                    <li @click="DateStyleOne" v-if="DateContentChildrenShow">YYYY-MM-DD</li>
                    <li @click="DateStyleTwo" v-if="DateContentChildrenShow">YYYY/MM/DD</li>
                    <li @click="DateStyleThree" v-if="DateContentChildrenShow">MM/DD/YYYY</li>
                    <li @click="DateStyleFour" v-if="DateContentChildrenShow">DD/MM/YYYY</li>
                </ul>
                <li @click="ClickHistory">
                    <p>{{ t('CelestialHeader.Sidebar.History.History') }}</p>
                    <p class="gt">&gt;</p>
                </li>
                <li>
                    <p>{{ t('CelestialHeader.Sidebar.Display') }}</p>
                    <div class="DisplayConstellation" ref="DisplayConstellation" @click="ToggleConstellation">
                        <div ref="DisplayConstellationBall"></div>
                    </div>
                </li>
                <li>
                    <p>{{ t('CelestialHeader.Sidebar.Logout') }}</p>
                </li>
            </ul>
            <div class="sjdDateAndMoonPhase">
                <!-- 月相 -->
                <div class="sjdMoonPhase">
                    <img :src="GetMoonPhaseSrc" alt="moonphase">
                    <div id="constellation">
                        <img :src="ConstellationImage" alt="constellationImage" v-if="ConstellationContentShow">
                    </div>
                </div>
                <!-- 当前日期 -->
                <div class="sjddate">
                    <p>{{ formattedDate() }}</p>
                    <p>{{ week }}</p>
                </div>
            </div>
        </div>
        <!-- 登录注册表单 -->
        <div class="loginDom" v-if="FormShow">
            <!-- 登录 -->
            <form 
                class="demo-login" 
                v-if="LoginShow"
            >
                <div id="LoginClose">
                    <p @click="LoginClose">×</p>
                </div>
                <h3>{{ t('CelestialHeader.Form.Login.LoginEntryTitle') }}</h3>
                <!-- 谷歌 -->
                <button class="ThirdPartyLogin">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="18" 
                        height="18" 
                        viewBox="0 0 18 18" 
                        fill="none" 
                        role="img" 
                        class="icon "
                    >
                        <path 
                            fill-rule="evenodd" 
                            clip-rule="evenodd" 
                            d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z" 
                            fill="#4285F4"
                        ></path>
                        <path 
                            fill-rule="evenodd" 
                            clip-rule="evenodd" 
                            d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z" 
                            fill="#34A853"
                        ></path>
                        <path 
                            fill-rule="evenodd" 
                            clip-rule="evenodd" 
                            d="M3.96409 10.7098C3.78409 10.1698 3.68182 9.59301 3.68182 8.99983C3.68182 8.40664 3.78409 7.82983 3.96409 7.28983V4.95801H0.957273C0.347727 6.17301 0 7.54755 0 8.99983C0 10.4521 0.347727 11.8266 0.957273 13.0416L3.96409 10.7098Z" 
                            fill="#FBBC05"
                        ></path>
                        <path 
                            fill-rule="evenodd" 
                            clip-rule="evenodd" 
                            d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" 
                            fill="#EA4335"
                        ></path>
                    </svg>
                    {{ t('CelestialHeader.Form.Login.SignGoogle') }}
                </button>
                <!-- 脸书 -->
                <button class="ThirdPartyLogin">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="18" 
                        height="18" 
                        viewBox="0 0 18 18" 
                        fill="none" 
                        role="img" 
                        class="icon"
                    >
                        <path 
                            fill="#3B5998" 
                            d="M17.385 0H0.615C0.277 0 0 0.277 0 0.615v16.77c0 0.338 0.277 0.615 0.615 0.615h7.733v-5.577H6.844V8.993h2.085V7.196c0-2.543 1.533-3.991 3.881-3.991 1.104 0 2.057 0.078 2.322 0.12v2.498l-1.511 0.005c-1.038 0-1.187 0.486-1.187 1.177v1.699h2.733l-0.35 2.733h-2.383V18h4.146c0.338 0 0.615-0.277 0.615-0.615V0.615C18 0.277 17.723 0 17.385 0z"
                        />
                    </svg>
                    {{ t('CelestialHeader.Form.Login.SignFacebook') }}
                </button>
                <hr class="sign-in">
                <p class="InputTitle">{{ t('CelestialHeader.Form.Login.mailbox') }}</p>
                <input 
                    type="text" 
                    v-model="Login_Email" 
                    class="ivu-input ivu-input-large"
                >
                <div class="PasswordBox">
                    <p class="InputTitle">{{ t('CelestialHeader.Form.Login.password') }}</p>
                    <p id="forgot" @click="ClickForgot">{{ t('CelestialHeader.Form.Login.forgot') }}</p>
                </div>
                <input 
                    type="password" 
                    v-model="Login_Password" 
                    class="ivu-input ivu-input-large"
                >
                <button 
                    @click="LoginSubmit" 
                    id="LoginSubmit" 
                    class="ivu-btn-long ivu-btn-large"
                >
                {{ t('CelestialHeader.Form.Login.Submit') }}
                </button>
                <div class="LoginBottom">
                    <p>
                        {{ t('CelestialHeader.Form.Login.NoAccount') }} 
                        <span 
                            @click="ClickGoToSign"
                        >
                            {{ t('CelestialHeader.Form.Login.Signup') }}
                        </span>
                    </p>
                </div>
            </form>
            <!-- 注册 -->
            <form 
                class="demo-Sign" 
                v-if="SignShow"
            >
                <div id="SignClose">
                    <p @click="SignClose">×</p>
                </div>
                <h3>{{ t('CelestialHeader.Form.Signin.SigninEntryTitle') }}</h3>
                <p class="InputTitle">{{ t('CelestialHeader.Form.Signin.Username') }}</p>
                <input 
                    type="text" 
                    v-model="Sign_Username" 
                    class="ivu-input ivu-input-large"
                >
                <p class="InputTitle">{{ t('CelestialHeader.Form.Signin.Birthday') }}</p>
                <input 
                    type="date" 
                    placeholder="Select date" 
                    v-model="Sign_Birthday"
                    class="ivu-input ivu-input-large"
                />
                <p class="InputTitle">{{ t('CelestialHeader.Form.Signin.mailbox') }}</p>
                <input 
                    type="email" 
                    v-model="Sign_Email" 
                    class="ivu-input ivu-input-large"
                >
                <p class="InputTitle">{{ t('CelestialHeader.Form.Signin.confirmmaillbox') }}</p>
                <input 
                    type="email" 
                    v-model="Sign_ConfirmEmail" 
                    class="ivu-input ivu-input-large"
                >
                <div 
                    v-if="!emailsMatch" 
                    class="error-message"
                >
                    {{ t('CelestialHeader.Form.Signin.NotEmails') }}
                </div>
                <button 
                    @click="SignSubmit" 
                    :disabled="!emailsMatch" 
                    id="SignSubmit" 
                    class="ivu-btn-long ivu-btn-large"
                >
                    {{ t('CelestialHeader.Form.Signin.Submit') }}
                </button>
            </form>
            <!-- 注册成功 -->
            <div 
                class="demo-Registered"
                v-if="RegisteredShow" 
            >
                <div id="RegisteredClose">
                    <p @click="RegisteredClose">×</p>
                </div>
                <h3>{{ t('CelestialHeader.Form.Registered.RegisteredEntryTitle') }}</h3>
                <p>{{ t('CelestialHeader.Form.Registered.content') }}</p>
                <div class="ClickRegisteredDom">
                    <button id="ClickRegistered" @click="ClickRegistered">{{ t('CelestialHeader.Form.Registered.setpassword') }}</button>
                </div>
            </div>
            <!-- 设置密码 -->
            <form 
                :model="formItem" 
                class="demo-SetPassword" 
                v-if="SetPasswordShow"
            > 
                <h3>{{ t('CelestialHeader.Form.SetPassword.SetPasswordEntryTitle') }}</h3>
                <p class="InputTitle">{{ t('CelestialHeader.Form.SetPassword.content') }}<span>XXXXXXXXX</span></p><br/>
                <p class="InputTitle">{{ t('CelestialHeader.Form.SetPassword.password') }}</p>
                <input 
                    type="password" 
                    v-model="SetPassword_password" 
                    class="ivu-input ivu-input-large"
                >
                <p class="InputTitle">{{ t('CelestialHeader.Form.SetPassword.confirmpassword') }}</p>
                <input 
                    type="password" 
                    v-model="SetPassword_Confirmpassword" 
                    class="ivu-input ivu-input-large"
                >
                <div v-if="!passwordsMatch" class="error-message">
                    {{ t('CelestialHeader.Form.SetPassword.NoPasswords') }}
                </div>
                <button 
                    @click="SetPasswordSubmit" 
                    :disabled="!passwordsMatch" 
                    id="SetPasswordSubmit" 
                    class="ivu-btn-long ivu-btn-large"
                >
                    {{ t('CelestialHeader.Form.SetPassword.Submit') }}
                </button>
            </form>
            <!-- 忘记密码 -->
            <form 
                class="demo-ForgetThePassword" 
                v-if="ForgetThePasswordShow"
            > 
                <div id="ForgetThePasswordClose">
                    <p @click="ForgetThePasswordClose">×</p>
                </div>
                <h3>{{ t('CelestialHeader.Form.Forget.ForgetEntryTitle') }}</h3>
                <p>{{ t('CelestialHeader.Form.Forget.content') }}</p>
                <hr class="sign-in2">
                <p class="InputTitle">{{ t('CelestialHeader.Form.Forget.mailbox') }}</p>
                <input 
                    type="email" 
                    v-model="ForgetThePassword_Email" 
                    class="ivu-input ivu-input-large"
                >
                <button 
                    @click="ForgetThePasswordSubmit" 
                    :disabled="!passwordsMatch" 
                    id="ForgetThePasswordSubmit" 
                    class="ivu-btn-long ivu-btn-large"
                >
                    {{ t('CelestialHeader.Form.Forget.Submit') }}
                </button>
            </form>
        </div>
        <!-- 会员订阅 -->
        <div class="SubscriptionManagementDom" v-if="SubscriptionManagementShow">
            <div class="SubscriptionManagement">
                <div id="SubscriptionManagementClose">
                    <p @click="SubscriptionManagementClose">×</p>
                </div>
                <div class="SubscriptionManagementHeader">
                    <h3>{{ t('CelestialHeader.Sidebar.Subscription.title') }}</h3>
                    <div class="SubscriptionManagementAvatar">
                        <img :src="UserImage" alt="head portrait">
                    </div>
                </div>
                <ul class="SubscriptionManagementTime" ref="SubscriptionManagementTime">
                    <li v-for="(option, index) in subscriptionOptions" :key="index" @click="ClickSubscriptionManagementTime(index)">
                        <h3>{{ option.time }}<span>{{ option.duration }}</span></h3>
                        <span>{{ option.price }}</span>
                        <p>{{ option.text }}</p>
                    </li>
                </ul>
                <ul class="SubscriptionManagementEnjoy">
                    <li>
                        <img src="../assets/star.png" alt="star">
                        <p>{{ t('CelestialHeader.Sidebar.Subscription.Enjoy1') }}</p>
                    </li>
                    <li>
                        <img src="../assets/star.png" alt="star">
                        <p>{{ t('CelestialHeader.Sidebar.Subscription.Enjoy2') }}</p>
                    </li>
                    <li>
                        <img src="../assets/star.png" alt="star">
                        <p>{{ t('CelestialHeader.Sidebar.Subscription.Enjoy3') }}</p>
                    </li>
                </ul>
                <button type="button" id="SubscriptionManagementSubmit">{{ t('CelestialHeader.Sidebar.Subscription.submit') }}</button>
            </div>
        </div>
         <!-- 历史查询 -->
        <div class="HistoryDom" v-if="HistoryShow">
            <div class="history">
                <div id="HistoryClose" @click="HistoryClose">
                    <p>×</p>
                </div>
                <div class="HistoryContent">
                    <h3>{{ t('CelestialHeader.Sidebar.History.Title') }}</h3>
                    <ul>
                        <li>
                            <p>我当前的感情状况如何，有什么建议？</p>
                            <span>2024-8-5</span>
                        </li>
                        <li>
                            <p>我在职业道路上即将面临哪些挑战和机遇？</p>
                            <span>2024-8-5</span>
                        </li>
                        <li>
                            <p>我的财务状况未来会有什么变化？</p>
                            <span>2024-8-5</span>
                        </li>
                        <li>
                            <p>我应该如何处理与家人或朋友的关系问题？</p>
                            <span>2024-8-5</span>
                        </li>
                        <li>
                            <p>我的精神成长道路上有什么重要的教训需要学习？</p>
                            <span>2024-8-5</span>
                        </li>
                        <li>
                            <p>我最近的决策是否正确，会有什么长远影响？</p>
                            <span>2024-8-5</span>
                        </li>
                        <li>
                            <p>我的人生目标和使命是什么，我应该如何朝着这个方向前进？</p>
                            <span>2024-8-5</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- gif -->
        <router-link
            to="/OnlineDivinastion"
        >
            <img src="../assets/suspension.gif" alt="gif" id="gotoonline">
        </router-link>
    </div>
</template>

<script>
import { ref,computed,onMounted, nextTick }  from 'vue';
import { Lunar } from 'lunar-javascript';
import { changeLocale } from "@/includes/i18n.js"
import { Icon } from 'view-ui-plus';
import anime from 'animejs';
import { useI18n } from 'vue-i18n';
export default {
    name:'CelestialHeader',
    directives: {
        'click-sidebar-outside': {
        beforeMount(el, binding) {
            const excludedElement = document.getElementById('ProfilePhoto');
            el.clickOutsideEvent = function(event) {
                if (!(el === event.target || el.contains(event.target)) &&
                    !(excludedElement === event.target || excludedElement.contains(event.target))) {
                    binding.value();
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
                document.body.removeEventListener('click', el.clickOutsideEvent);
            }
        }
    },
    setup() {
        // 语言切换
        const { t } = useI18n();
        const switchLocale = (locale) => {
            changeLocale(locale);
        }
        // logo
        const logoImage = computed(() => {
            return window.innerWidth < 1200 ? require('@/assets/logo2.png') : require('@/assets/logo.png');
        });
        // 导航栏
        const links = computed(() => [
            { name: 'CelestialHome', text: t('CelestialHeader.navigation.home') },
            { name: 'OnlineDivinastion', text: t('CelestialHeader.navigation.onlinedivination') },
            { name: 'DailyHoroscope', text: t('CelestialHeader.navigation.dailyhoroscope') },
            { name: 'AboutUs', text: t('CelestialHeader.navigation.aboutus') }
        ]);
        const currentDate = ref(new Date());
        // 切换日期样式
        const DateStyle = ref(localStorage.getItem('DateStyleValue') || "yyyymmdd/");
        DateStyleValueFunction();
        // 计算当前日期
        function formattedDate() {
            let year = currentDate.value.getFullYear();
            let month = (currentDate.value.getMonth() + 1).toString().padStart(2, '0');
            let day = currentDate.value.getDate().toString().padStart(2, '0');  
            if(DateStyle.value == "yyyymmdd_") {
                return `${year}-${month}-${day}`;
            }else if(DateStyle.value == "mmddyyyy/") {
                return `${month}/${day}/${year}`;
            }else if(DateStyle.value == "ddmmyyyy/") {
                return `${day}/${month}/${year}`;
            }else {
                return `${year}/${month}/${day}`;
            }
        }
        function DateStyleValueFunction() {
            localStorage.setItem('DateStyleValue', DateStyle.value);
            formattedDate();
        }
        function DateStyleOne() {
            DateStyle.value = "yyyymmdd_";
            DateStyleValueFunction();
        }
        function DateStyleTwo() {
            DateStyle.value = "yyyymmdd/";
            DateStyleValueFunction();
        }
        function DateStyleThree() {
            DateStyle.value = "mmddyyyy/";
            DateStyleValueFunction();
        }
        function DateStyleFour() {
            DateStyle.value = "ddmmyyyy/";
            DateStyleValueFunction();
        }
        const week = computed(() => {
            let days = [
                t('CelestialHeader.week.Sunday'),
                t('CelestialHeader.week.Monday'),
                t('CelestialHeader.week.Tuesday'),
                t('CelestialHeader.week.Wednesday'),
                t('CelestialHeader.week.Thursday'),
                t('CelestialHeader.week.Friday'),
                t('CelestialHeader.week.Sunday')
            ];
            return days[currentDate.value.getDay()];
        });
        // 月相
        const GetMoonPhaseSrc = ref(null);
        const MoonPhaseSrc = ref([
            { DayID: '1',ImageSrc:require('@/assets/Moon phases/moon (1).png')},
            { DayID: '2',ImageSrc:require('@/assets/Moon phases/moon (2).png')},
            { DayID: '3',ImageSrc:require('@/assets/Moon phases/moon (3).png')},
            { DayID: '4',ImageSrc:require('@/assets/Moon phases/moon (4).png')},
            { DayID: '5',ImageSrc:require('@/assets/Moon phases/moon (5).png')},
            { DayID: '6',ImageSrc:require('@/assets/Moon phases/moon (6).png')},
            { DayID: '7',ImageSrc:require('@/assets/Moon phases/moon (7).png')},
            { DayID: '8',ImageSrc:require('@/assets/Moon phases/moon (8).png')},
            { DayID: '9',ImageSrc:require('@/assets/Moon phases/moon (9).png')},
            { DayID: '10',ImageSrc:require('@/assets/Moon phases/moon (10).png')},
            { DayID: '11',ImageSrc:require('@/assets/Moon phases/moon (11).png')},
            { DayID: '12',ImageSrc:require('@/assets/Moon phases/moon (12).png')},
            { DayID: '13',ImageSrc:require('@/assets/Moon phases/moon (13).png')},
            { DayID: '14',ImageSrc:require('@/assets/Moon phases/moon (14).png')},
            { DayID: '15',ImageSrc:require('@/assets/Moon phases/moon (15).png')},
            { DayID: '16',ImageSrc:require('@/assets/Moon phases/moon (16).png')},
            { DayID: '17',ImageSrc:require('@/assets/Moon phases/moon (17).png')},
            { DayID: '18',ImageSrc:require('@/assets/Moon phases/moon (18).png')},
            { DayID: '19',ImageSrc:require('@/assets/Moon phases/moon (19).png')},
            { DayID: '20',ImageSrc:require('@/assets/Moon phases/moon (20).png')},
            { DayID: '21',ImageSrc:require('@/assets/Moon phases/moon (21).png')},
            { DayID: '22',ImageSrc:require('@/assets/Moon phases/moon (22).png')},
            { DayID: '23',ImageSrc:require('@/assets/Moon phases/moon (23).png')},
            { DayID: '24',ImageSrc:require('@/assets/Moon phases/moon (24).png')},
            { DayID: '25',ImageSrc:require('@/assets/Moon phases/moon (25).png')},
            { DayID: '26',ImageSrc:require('@/assets/Moon phases/moon (26).png')},
            { DayID: '27',ImageSrc:require('@/assets/Moon phases/moon (27).png')},
            { DayID: '28',ImageSrc:require('@/assets/Moon phases/moon (28).png')},
            { DayID: '29',ImageSrc:require('@/assets/Moon phases/moon (29).png')},
            { DayID: '30',ImageSrc:require('@/assets/Moon phases/moon (30).png')},
        ]);
        function updateLunarDate() {
            const lunar = Lunar.fromDate(currentDate.value);
            for(let i = 0;i < 30;i++) {
                if(lunar.getDay() == MoonPhaseSrc.value[i].DayID) {
                    GetMoonPhaseSrc.value = MoonPhaseSrc.value[i].ImageSrc;
                }
            }
        }

        // 初始用户头像
        const UserImage = ref(require('@/assets/profile picture.jpg'));

        // 侧边栏
        function ForgetSidebarClose() {
            SidebarShow.value = false;
        }
        const SidebarShow = ref(true);
        function ClickSidebar() {
            SidebarShow.value = !SidebarShow.value;
        }
        function closeSidebar() {
            SidebarShow.value = false;
        }
        const IsNobility = ref(require('@/assets/nobility.png'));
        const ServiceLife = ref(`${ t('CelestialHeader.Sidebar.ServiceLife') }2026-8-1`);
        // 用户信息
        const user = ref({
            name:"Fiona Boora",
            birthday:"2004-10-31"
        })
        const isEditing = ref(false);
        const nameInput = ref(null);

        const toggleEditing = () => {
            if (!isEditing.value) {
                isEditing.value = true;
                nextTick(() => {
                if (nameInput.value) {
                    nameInput.value.focus();
                }
                });
            } else {
                isEditing.value = false;
            }
        };
        const isEditingBirthday = ref(false);
        const BirthdayInput = ref(null);

        const toggleEditingBirthday = () => {
            if (!isEditingBirthday.value) {
                isEditingBirthday.value = true;
                nextTick(() => {
                if (BirthdayInput.value) {
                    BirthdayInput.value.focus();
                }
                });
            } else {
                isEditingBirthday.value = false;
            }
        };
        // 控制侧边栏下拉框
        function Toggle(toggleShow,toggleContent,toggleContentChildrenShow,toggleHeight,GT) {
            toggleShow.value = !toggleShow.value;
            const targetHeight = toggleShow.value ? `${toggleHeight.value}px` : '0px';
            anime({
                targets: toggleContent.value,
                easing: 'easeInOutSine',
                maxHeight: targetHeight,
                opacity: toggleShow.value ? 1 : 0,
                duration: 300,
                begin: () => {
                    if (toggleShow.value === true) {
                        toggleContentChildrenShow.value = true;
                    }
                },
                complete: () => {
                    if (toggleShow.value === false) {
                        toggleContentChildrenShow.value = false;
                    }
                }
            });
            anime({
                targets: GT.value,
                easing: 'easeInOutSine',
                duration: 300,
                rotate: toggleShow.value ? 90 : 0,
            });
        }
        // 控制侧边栏下拉框初始化
        function ToggleInitialize(toggleShow,toggleContent,toggleContentChildrenShow,toggleHeight) {
            toggleHeight.value = toggleContent.value.scrollHeight;
            toggleShow.value = false;
            toggleContentChildrenShow.value = false;
        }
        // 语言
        const LanguageContentShow = ref(true);
        const LanguageContent  = ref(null);
        const LanguageContentChildrenShow = ref(true);
        const LanguageHeight = ref(0);
        const LanguageGT = ref(null);
        function ToggleLanguage() {
            Toggle(LanguageContentShow,LanguageContent,LanguageContentChildrenShow,LanguageHeight,LanguageGT);
        }
        // 控制日期显示
        // 日期
        const DateContentShow = ref(true);
        const DateContent  = ref(null);
        const DateContentChildrenShow = ref(true);
        const DateHeight = ref(0);
        const DateGT = ref(null);
        function ToggleDate() {
            Toggle(DateContentShow,DateContent,DateContentChildrenShow,DateHeight,DateGT);
        }
        // 星座
        const ConstellationImage = ref(require('@/assets/twelve constellations/Aries.png'));

        const ConstellationContentShow = ref(false);
        const DisplayConstellation = ref(null);
        const DisplayConstellationBall = ref(null);
        function ToggleConstellation() {
            ConstellationContentShow.value = !ConstellationContentShow.value;
            anime({
                targets: DisplayConstellationBall.value,
                easing: 'easeInOutSine',
                duration: 300,
                left: ConstellationContentShow.value ? "55%" : "10%",
            });
            anime({
                targets:DisplayConstellation.value,
                easing: 'easeInOutSine',
                duration: 300,
                backgroundColor: ConstellationContentShow.value ? "#443256" : "#ddd",
            })
        }
        // 订阅管理
        const SubscriptionManagementShow = ref(false);
        const SubscriptionManagementTime = ref(null);
        const  subscriptionOptions =  computed(() =>[
            { time: t('CelestialHeader.Sidebar.Subscription.time1'),duration: t('CelestialHeader.Sidebar.Subscription.duration1'), price: '$49.9', text: t('CelestialHeader.Sidebar.Subscription.text') },
            { time: t('CelestialHeader.Sidebar.Subscription.time2'),duration: t('CelestialHeader.Sidebar.Subscription.duration2'), price: '$19.9', text: t('CelestialHeader.Sidebar.Subscription.text') },
            { time: t('CelestialHeader.Sidebar.Subscription.time3'),duration: t('CelestialHeader.Sidebar.Subscription.duration3'), price: '$9.9', text: t('CelestialHeader.Sidebar.Subscription.text') }
        ])
        function ClickSubscription() {
            SubscriptionManagementShow.value = true;
        }
        function SubscriptionManagementClose() {
            SubscriptionManagementShow.value = false;
        }
        function ClickSubscriptionManagementTime(index) {
            const children = SubscriptionManagementTime.value.children;
            const defaultStyle = {
                backgroundColor: "#fff",
                h3Color: "#2e2e2e",
                spanColor: "#443256",
                pColor: "#222"
            };
            const activeStyle = {
                backgroundColor: "#443256",
                h3Color: "#fff",
                spanColor: "#fff",
                pColor: "#fff"
            };
            Array.from(children).forEach(child => {
                child.style.backgroundColor = defaultStyle.backgroundColor;
                child.querySelector('h3').style.color = defaultStyle.h3Color;
                child.querySelectorAll('span')[0].style.color = defaultStyle.spanColor;
                child.querySelectorAll('span')[1].style.color = defaultStyle.spanColor;
                child.querySelector('p').style.color = defaultStyle.pColor;
            });
            const activeChild = children[index];
            activeChild.style.backgroundColor = activeStyle.backgroundColor;
            activeChild.querySelector('h3').style.color = activeStyle.h3Color;
            activeChild.querySelectorAll('span')[0].style.color = activeStyle.spanColor;
            activeChild.querySelectorAll('span')[1].style.color = activeStyle.spanColor;
            activeChild.querySelector('p').style.color = activeStyle.pColor;
        }
        // 历史列表查询
        const HistoryShow =ref(false);
        function HistoryClose() {
            HistoryShow.value = false;
        }
        function ClickHistory() {
            HistoryShow.value = true;
        }

        // 用户登录表单
        const FormShow = ref(false);

        const LoginShow = ref(false);
        const SignShow = ref(false);
        const SetPasswordShow = ref(false);
        const ForgetThePasswordShow = ref(false);
        const RegisteredShow = ref(false);

        const Login_Email = ref("");
        const Login_Password = ref("");

        const Sign_Username = ref("");
        const Sign_Birthday = ref("");
        const Sign_Email = ref("");
        const Sign_ConfirmEmail = ref("");

        const SetPassword_password = ref("");
        const SetPassword_Confirmpassword = ref("");

        const ForgetThePassword_Email = ref("");
        // 打开登录表单
        function LoginToggleOpen() {
            FormShow.value = true;
            LoginShow.value = true;
        }
        // 关闭登录表单
        function LoginClose() {
            FormShow.value = false;
            LoginShow.value = false;
        }
        // 点击登录
        function LoginSubmit() {

        }
        // 点击忘记密码
        function ClickForgot() {
            toggleForm(ForgetThePasswordShow,LoginShow);
        }
        // 关闭忘记密码
        function ForgetThePasswordClose() {
            toggleForm(LoginShow,ForgetThePasswordShow);
        }
        // 去注册
        function ClickGoToSign() {
            toggleForm(SignShow,LoginShow);
        }
        // 关闭注册
        function SignClose() {
            toggleForm(LoginShow,SignShow);
        }
        // 点击注册
        function SignSubmit() {
            console.log("1");
            toggleForm(RegisteredShow,SignShow);
        }
        // 取消激活账号
        function RegisteredClose() {
            toggleForm(SignShow,RegisteredShow);
        }
        // 点击激活账号
        function ClickRegistered() {
            toggleForm(SetPasswordShow,RegisteredShow);
        }
        // 点击设置密码
        function SetPasswordSubmit() {

        }
        // 提交忘记密码
        function ForgetThePasswordSubmit() {

        }
        // 切换表单
        function toggleForm(show,hide) {
            show.value = true;
            hide.value = false;
        }
        // 确认密码
        const passwordsMatch = computed(() => SetPassword_password.value === SetPassword_Confirmpassword.value);
        // 确认邮箱
        const emailsMatch = computed(() => Sign_Email.value === Sign_ConfirmEmail.value);
        // 挂载
        onMounted(() => {
            nextTick(() => {
                updateLunarDate();
                // 语言下拉初始化
                ToggleInitialize(LanguageContentShow,LanguageContent,LanguageContentChildrenShow,LanguageHeight);
                // 日期下拉初始化
                ToggleInitialize(DateContentShow,DateContent,DateContentChildrenShow,DateHeight);
                SidebarShow.value = false;
            })
        })
        return {
            // 语言切换
            t,
            switchLocale,
            // logo
            logoImage,
            // 导航栏
            links,
            // 计算当前日期
            formattedDate,
            week,
            // 计算当前日期样式
            DateStyleOne,
            DateStyleTwo,
            DateStyleThree,
            DateStyleFour,
            // 月相
            GetMoonPhaseSrc,
            // 星座
            ConstellationImage,
            // 用户头像
            UserImage,
            // 表单
            FormShow,
            LoginShow,
            SignShow,
            RegisteredShow,
            SetPasswordShow,
            ForgetThePasswordShow,
            Login_Email,
            Login_Password,
            Sign_Username,
            Sign_Birthday,
            Sign_Email,
            Sign_ConfirmEmail,
            SetPassword_password,
            SetPassword_Confirmpassword,
            ForgetThePassword_Email,
            LoginToggleOpen,
            LoginClose,
            LoginSubmit,
            ClickForgot,
            ClickGoToSign,
            SignClose,
            SignSubmit,
            RegisteredClose,
            ClickRegistered,
            SetPasswordSubmit,
            ForgetThePasswordClose,
            ForgetThePasswordSubmit,
            toggleForm,
            // 判断邮箱与密码一致性
            passwordsMatch,
            emailsMatch,
            // 侧边栏
            ForgetSidebarClose,
            ClickSidebar,
            closeSidebar,
            SidebarShow,
            IsNobility,
            ServiceLife,
            // 用户信息
            user,
            isEditing,
            toggleEditing,
            nameInput,
            isEditingBirthday,
            toggleEditingBirthday,
            BirthdayInput,
            // 控制语言日期星座显示
            LanguageContentShow,
            LanguageContentChildrenShow,
            LanguageContent,
            LanguageGT,
            ToggleLanguage,
            DateContentShow,
            DateContentChildrenShow,
            DateContent,
            DateGT,
            ToggleDate,
            ConstellationContentShow,
            DisplayConstellation,
            DisplayConstellationBall,
            ToggleConstellation,
            // 订阅管理
            ClickSubscription,
            SubscriptionManagementShow,
            SubscriptionManagementClose,
            SubscriptionManagementTime,
            subscriptionOptions,
            ClickSubscriptionManagementTime,
            // 历史查询
            HistoryShow,
            HistoryClose,
            ClickHistory
        };
      
  },
  components: {
    Icon
  }
}
</script>



<style scoped>
    header {
        width: 100%;
        background-color: #f4f2f7;
        padding: 20px 5% !important;
        justify-content: space-between;
        z-index: 1000;
        font-family: 'SiYuanRegular';
        transform: translateZ(1000px);
    }
    header>nav>ul {
        display: flex;
        align-items: center;
    }
    header,header>nav,header>div,header>div>div,#MoonPhase {
        display: flex !important;
        align-items: center;
    }
    #logo {
        width: 40px;
        margin-right: 20px;
    }
    /* 导航栏样式 */
    header>nav>ul>a {
        font-size: 22px;
        margin: 0px 20px;
        color: #443256;
    }
    header>nav>ul>a:hover {
        color: #e7d77b;
        transition: ease 0.5s;
    }
    .active_link {
        text-decoration-line: underline;
        text-decoration-style: solid;
        color: #e4cb3b;
    }
    /* 时间 */
    #date {
        text-align: right;
        font-size: 18px;
        font-family: 'SiYuanRegular';
    }
    /* 月相 */
    #MoonPhase {
        margin: 0px 20px;
        border: 2px solid #443256;
        border-radius: 100%;
        background-color: #000;
        justify-content: center;
        position: relative;
    }
    #moon {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
    #MoonPhase,#ProfilePhoto,#moon {
        width: 50px;
        height: 50px;
    }
    #MoonPhase>img {
        width: 100%;
    }
    #constellation {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #constellation>img {
        width: 70%;
    }
    /* 头像 */
    #ProfilePhoto {
        border-radius: 100%;
        overflow: hidden;
        cursor: pointer;
    }
    #ProfilePhoto>img {
        width: 100%;
    }
    #ProfilePhoto img:hover .SideBar {
        display: block;
    }
    /* 是否点击星座 */
    .constellation {
        width: 40px;
        height: 20px;
        background-color: #443256;
        border-radius: 15px;
        position: relative;
        display: flex;
        align-items: center;
        transition: ease .5s;
    }
    .constellation>div {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background-color: #fff;
        position: absolute;
        right: 7%;
        transition: ease .5s;
    }
    /* 登录 */
    #login {
        margin-left: 20px;
        cursor: pointer;
        transition: ease .5s;
        font-size: 18px;
    }
    #login:hover {
        color: #baa725;
    }
    .ProfileBG {
        background-color: #ddd;
    }
    /* 登录区域 */
    .loginDom,.SubscriptionManagementDom,.HistoryDom {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1002;
        transform: translateZ(1000px);
        background-color: #3333335d !important;
        font-size: 18px;
        color: #2e2e2e;
    }
    .loginDom .ivu-input {
        margin: 15px 0 30px 0;
    }
    .loginDom .ivu-input:focus {
        border-color: #443256;
        outline: none;
        box-shadow: 0 0 4px #966fbc;
    }
    #LoginClose,#SignClose,#RegisteredClose,#ForgetThePasswordClose,#SubscriptionManagementClose,#HistoryClose {
        display: flex;
        justify-content: end;
    }
    #LoginClose>p,#SignClose>p,#RegisteredClose>p,#ForgetThePasswordClose>p,#SubscriptionManagementClose>p,#HistoryClose {
        font-size: 30px;
        cursor: pointer;
    }
    #LoginSubmit,#SignSubmit,#ForgetThePasswordSubmit,#SetPasswordSubmit {
        background-color: #443256;
        color: #fff;
        border: 0;
        margin-top: 10px;
        cursor: pointer;
        transition: ease .5s;
        font-family: 'SiYuanSemiBold';
    }
    #LoginSubmit:hover,#SignSubmit:hover,#ForgetThePasswordSubmit:hover,#SetPasswordSubmit:hover {
        background-color: #5e4576;
    }
    .sign-in,.sign-in2 {
        height: 1px;
        margin: 30px 0;
        padding: 0;
        overflow: visible;
        border: none;
        background-color: #e7e7e9;
        color: #6e6d7a;
        text-align: center;
    }
    .sign-in::after,.sign-in2::after {
        display: inline-block;
        position: relative;
        top: -15px;
        padding: 0 16px;
        background: #fff;
    }
    .sign-in::after {
        content:"or sign in with email";
    }
    .sign-in2::after {
        content:"Your email";
    }
    .ThirdPartyLogin {
        width: 100%;
        border: 1px solid #443256;
        background-color: transparent;
        padding:15px 0;
        margin: 30px 0;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ThirdPartyLogin>svg {
        margin-right: 20px;
    }
    .PasswordBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .InputTitle {
        font-family: 'SiYuanSemiBold';
    }
    #forgot {
        font-family: 'SiYuanRegular';
        text-decoration-line: underline;
        text-decoration-style: solid;
        cursor: pointer;
    }
    .error-message {
        color: red;
    }
    .success-message {
        color: #443256;
    }
    .demo-login,.demo-Sign,.demo-SetPassword,.demo-ForgetThePassword,.demo-Registered,.SubscriptionManagement,.history{
        width: 35%;
        margin: 0 auto !important;
        background-color: #fff;
        padding: 20px 50px 50px 50px;
        border-radius: 20px;
        font-family: 'SiYuanRegular';
    }
    .demo-login>h3,.demo-Sign>h3,.demo-SetPassword>h3,.demo-ForgetThePassword>h3,.demo-Registered>h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #443256;
        font-family: 'SiYuanSemiBold';
    }
    .demo-login,.demo-Sign,.demo-SetPassword,.demo-ForgetThePassword,.demo-Registered .ivu-input:focus {
        border: 0;
    }
    .demo-login,.demo-Sign,.demo-SetPassword,.demo-ForgetThePassword,.demo-Registered .ivu-input:hover {
        border-color:#443256;
    }
    .LoginBottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        font-size: 14px;
        color: #ccc;
    }
    .LoginBottom>p>span {
        cursor: pointer;
        color: #443256;
        text-decoration-line: underline;
        text-decoration-style: solid;
    }
    #gotoonline {
        position: fixed;
        right: 10px;
        bottom: 100px;
        width: 100px;
        cursor: pointer;
    }
    .ClickRegisteredDom {
        display: flex;
        justify-content: center;
    }
    #ClickRegistered {
        background-color: transparent;
        border: 0;
        color: #443256;
        text-decoration-line: underline;
        text-decoration-style: solid;
        margin-top: 50px;
        cursor: pointer;
    }
    /* 侧边栏 */
    .sidebar {
        position: absolute;
        right: 150px;
        z-index: 1001;
        transform: translateZ(1000px);
        box-shadow: 0px 0px 15px rgb(205, 205, 205);
        background-color: #fff;
        width: 320px;
        border-radius: 15px;
        color: #2e2e2e;
    }
    .SidebarHeader {
        padding: 20px;
        display: flex;
        align-items: center;
    }
    .SidebarAvatar {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 20px;
    }
    .SidebarAvatar>img {
        width: 100%;
    }
    #SidebarClose {
        display: flex;
        justify-content: end;
    }
    #SidebarClose>p {
        font-size: 24px;
        margin: 20px 20px 0 0;
        cursor: pointer;
    }
    /* 用户信息 */
    .userinfo {
        width: 180px;
    }
    .userinfo>span {
        display: inline-block;
        height: 30px;
        font-size: 20px;
        font-family: 'SiYuanSemiBold';
    }
    .userinfo>input {
        width: 100%;
        height: 30px;
        font-size: 20px;
        font-family: 'SiYuanSemiBold';
    }
    .userinfo>input:focus {
        border: 0;
        outline: none;
    }
    /* 图标 */
    #ios-create {
        font-size: 20px;
        margin-left: 5px;
        cursor: pointer;
    }
    .userinfo>div {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 14px;
    }
    .userinfo>div>img {
        margin-right: 10px;
    }
    .userbirthday>span {
        height: 24px;
        font-size: 16px;
        display: inline-block;
    }
    .userbirthday>input {
        height: 24px;
        font-size: 16px;
    }
    .userbirthday>input:focus {
        border: 0;
        outline: none;
    }
    /* 侧边栏内容 */
    #SidebarClose {
        display: none;
    }
    .SidebarContent>ul,.SidebarContent {   
        list-style-type: none;
    }
    .SidebarContent li {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* font-family: 'SiYuanRegular'; */
        font-family: 'SiYuanSemiBold';
        transition: ease .3s;
        cursor: pointer;
        font-size: 16px;
    }
    .SidebarContent li:hover {
        background-color: #eee;
    }
    .gt {
        font-size: 18px;
    }
    .SidebarContent>ul li {
        padding-left: 40px;
        font-size: 14px;
        font-family: 'SiYuanRegular';
        color: #606060;
    }
    /* 侧边栏页面跳转链接 */
    .SidebarNav {
        display: none;
    }
    .SidebarNav>a {
        color: #2e2e2e !important;
        display: block;
    }
    .SidebarNav a:last-child {
        border-bottom: 1px solid #ddd !important;
    }
    .SidebarContent>li:nth-child(2) {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }
    .SidebarContent>li:last-child {
        border-top: 1px solid #ddd;
        padding-bottom: 20px;
        text-decoration-line: underline;
        text-decoration-style: solid;
    }
    /* 显示星座 */
    .DisplayConstellation {
        width: 40px;
        height: 20px;
        border-radius: 20px;
        background-color: #ddd;
        display: flex;
        align-items: center;
        position: relative;
    }
    .DisplayConstellation>div {
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 100%;
        background-color: #fff;
        left: 10%;
    }
    /* 手机端的星座与日期 */
    .sjdDateAndMoonPhase {
        display: none;
    }
    /* 订阅管理 */
    .SubscriptionManagementHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    .SubscriptionManagementHeader>h3 {
        width: 350px;
        font-size: 30px;
        font-family: 'SiYuanSemiBold';
        color: #443256;
    }
    .SubscriptionManagementHeader>div {
        width: 75px;
        height: 75px;
        border-radius: 100%;
        overflow: hidden;
    }
    .SubscriptionManagementHeader>div>img {
        width: 100%;
    }
    .SubscriptionManagementTime,.SubscriptionManagementEnjoy {
        list-style-type: none
    }
    .SubscriptionManagementTime {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 50px 0;
    }
    .SubscriptionManagementTime>li {
        width: 32%;
        padding: 25px 10px;
        box-shadow: 0px 0px 15px rgb(205, 205, 205);
        text-align: center;
        border-radius: 10px;
        cursor: pointer;
        transition: ease .5s;
    }
    .SubscriptionManagementTime>li>h3 {
        font-family: 'SiYuanSemiBold';
        font-size: 18px;
    }
    .SubscriptionManagementTime>li>span {
        display: inline-block;
        font-family: 'SiYuanSemiBold';
        font-size: 34px;
        color: #443256;
        margin: 10px 0;
    }
    .SubscriptionManagementTime>li>p {
        font-family: 'SiYuanRegular';
        font-size: 12px;
        color: #888;
    }
    .SubscriptionManagementEnjoy>li {
        display: flex;
        align-items: center;
        margin: 15px 0;
    }
    .SubscriptionManagementEnjoy>li>img {
        width: 25px;
        margin-right: 10px;
    }
    .SubscriptionManagementEnjoy>li>p {
        font-size: 16px;
        font-family: 'SiYuanRegular';
    }
    #SubscriptionManagementSubmit {
        width: 100%;
        font-size: 24px;
        border: 0;
        background-color:#443256;
        color: #fff;
        padding: 10px 0;
        margin: 20px 0 0 0;
        font-family: 'SiYuanSemiBold';
        border-radius: 10px;
        cursor: pointer;
    }
    /* 历史记录列表 */
    .HistoryContent {
        max-height: 527px;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .HistoryContent::-webkit-scrollbar {
        display: none;
    }
    .HistoryContent>h3 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        color: #443256;
        font-family: 'SiYuanSemiBold';
    }
    .HistoryContent>ul {
        list-style-type: none;
    }
    .HistoryContent>ul>li {
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px;
    }
    .HistoryContent>ul>li:last-child {
        border-bottom: 0;
    }
    .HistoryContent>ul>li>p {
        width: 80%;
        line-height: 36px;
        max-height: 72px;
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        font-family: 'SiYuanRegular';
    }
    .HistoryContent>ul>li>p::-webkit-scrollbar {
        display: none;
    }
    @media(max-width:1200px) {
        header ul {
            display: none;
        }
        header {
            position: fixed;
            top: 0;
        }
        header>nav>ul {
            display: none
        }
        #MoonPhase,#date {
            display: none !important;
        }
        #logo {
            width: 50%;
        }
        /* 侧边栏 */
        .sidebar {
            position: fixed;
            right: 0;
            left: 0;
            top: 0;
            height: 100vh;
            width: 300px;
            border-radius: 0;
        }
        #SidebarClose {
            display: flex;
        }
        .SidebarHeader {
            padding: 0 20px 20px 20px;
        }
        .SidebarContent {
            overflow-y: auto;
            height: calc(100vh - 27%);
        }
        .SidebarAvatar {
            border: 2px solid #443256;
        }
        .SidebarNav {
            display: block;
        }
        /* 手机端的星座与日期 */
        .sjdDateAndMoonPhase {
            display: flex;
            position: absolute;
            bottom: 20px;
        }
        .sjddate {
            display: block !important;
            text-align: left !important;
            color: #ccc;
        }
        .sjdMoonPhase {
            display: block !important;
        }
        .sjdMoonPhase {
            margin: 0px 20px;
            border: 2px solid #443256;
            border-radius: 100%;
            background-color: #000;
            justify-content: center;
            position: relative;
            width: 50px;
            height: 50px;
        }
        .sjdMoonPhase>img {
            width: 100%;
        }
        /* 侧边栏 */
        .sidebar {
            width: 320px;
        }
    }
    @media(max-width:1440px) {
        /* 登录 */
        .demo-login,.demo-Sign,.demo-SetPassword,.demo-ForgetThePassword,.demo-Registered,.SubscriptionManagement,.history{
            width: 50%;
            line-height: 35px;
        }
    }
    @media(max-width:1024px) {
        /* 登录 */
        .demo-login,.demo-Sign,.demo-SetPassword,.demo-ForgetThePassword,.demo-Registered,.SubscriptionManagement,.history{
            width: 55%;
        }
    }
    @media (max-width:768px) {
        .history {
            width: 60%;
        }
        .HistoryContent>ul>li {
            flex-wrap: wrap;
            padding: 10px;
        }
        .HistoryContent>ul>li>p {
            width: 100%;
        }
        .demo-login>h3,.demo-Sign>h3,.demo-SetPassword>h3,.demo-ForgetThePassword>h3,.demo-Registered>h3 {
            font-size: 28px;
            line-height: 45px;
        }
        .ThirdPartyLogin {
            padding: 10px;
        }
        .SubscriptionManagement {
            width: 60%;
        }
        .SubscriptionManagementHeader>h3 {
            width: 300px;
            font-size: 28px;
        }
        .SubscriptionManagementHeader>div {
            width: 50px;
            height: 50px;
        }
        .SubscriptionManagementTime>li>p {
            display: none;
        }
    }
    @media (max-width:480px) {
        .sidebar {
            width: 100%;
        }
        .history {
            width: 70%;
            padding: 20px 30px 30px 30px;
        }
        .HistoryContent p {
            font-size: 16px;
        }
        .HistoryContent span {
            font-size: 16px;
        }
        .SidebarContent li {
            padding: 15px 20px;
        }
        /* 订阅 */
        .SubscriptionManagementAvatar {
            display: none;
        }
        .SubscriptionManagement {
            width: 80%;
            padding: 20px 30px 30px 30px;
        }
        .SubscriptionManagementHeader>h3 {
            width: 100%;
            font-size: 22px;
        }
        .SubscriptionManagementTime {
            margin: 25px 0;
        }
        .SubscriptionManagementTime>li {
            padding: 10px 5px;
        }
        .SubscriptionManagementTime>li>h3 {
            font-size: 14px;
        }
        .SubscriptionManagementTime>li>span {
            font-size: 18px;
            margin: 0;
        }
        .SubscriptionManagementEnjoy>li>p {
            font-size: 12px;
        }
        .SubscriptionManagementEnjoy>li {
            margin: 5px 0;
        }
        #SubscriptionManagementSubmit {
            font-size: 18px;
        }
        /* 登录注册 */
        .demo-login,.demo-Sign,.demo-SetPassword,.demo-ForgetThePassword,.demo-Registered,.SubscriptionManagement,.history{
            width: 80%;
            padding: 20px 30px 30px 30px;
            font-size: 14px;
        }
        .demo-login>h3, .demo-Sign>h3, .demo-SetPassword>h3, .demo-ForgetThePassword>h3, .demo-Registered>h3 {
            font-size: 22px;
        }
        .ThirdPartyLogin {
            font-size: 12px;
            margin: 15px 0;
        }
        .sign-in {
            display: none;
        }
        .loginDom .ivu-input {
            margin: 5px 0px 10px 0px;
        }
    }
    @media (max-width:376px) {
        .SubscriptionManagementEnjoy>li>p {
            font-size: 14px;
        }
        .SubscriptionManagementTime>li {
            box-shadow: 0px 0px 5px rgb(205, 205, 205)
        }
        #SubscriptionManagementSubmit {
            padding: 5px 0;
            font-size: 16px;
        }
    }
    @media (max-width:320px) {
        #ProfilePhoto {
            width: 40px;
            height: 40px;
        }
        .HistoryContent p {
            font-size: 14px;
            line-height: 30px;
        }
        .HistoryContent>h3 {
            font-size: 24px;
        }
        .HistoryContent span {
            font-size: 14px;
        }
        .HistoryContent>h3 {
            margin-bottom: 18px;
        }
        .LoginBottom {
            font-size: 12px;
        }
        .demo-login,.demo-Sign,.demo-SetPassword,.demo-ForgetThePassword,.demo-Registered,.SubscriptionManagement,.history{
            line-height: 25px;
        }
        .demo-login>h3, .demo-Sign>h3, .demo-SetPassword>h3, .demo-ForgetThePassword>h3, .demo-Registered>h3 {
            line-height: 35px;
        }
        .SubscriptionManagementTime>li {
            box-shadow: 0px 0px 0px;
            border: 1px solid #5e4576;
        }
        .SubscriptionManagementTime>li>h3 {
            font-size: 10px;
        }
        .SubscriptionManagementEnjoy>li>p {
            font-size: 12px;
        }
    }
</style>