<template>
  <div>
    <celestial-header></celestial-header>
    <router-view></router-view>
    <celestial-footer></celestial-footer>
    <!-- <ce-shi></ce-shi> -->
    <!-- <online-card-animation></online-card-animation> -->
  </div>
</template>

<script>
import CelestialHeader from './components/CelestialHeader.vue';
import CelestialFooter from './components/CelestialFooter.vue';
// import CeShi from './components/CeShi.vue';
// import OnlineCardAnimation from './components/Animation/OnlineCardAnimation.vue';
export default {
  name: 'App',
  setup() {
    
  },
  components: {
    CelestialHeader,
    CelestialFooter,
    // CeShi,
    // OnlineCardAnimation,
  }
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
</style>
